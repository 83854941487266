
/**
 * To add a new part of script:
 * 1. Add a new .upil script file in this directory
 * 2. Add a new import below
 * 3. Add the import name to the scripts[] array
 */

import closing from '!raw-loader!./closing.upil' // eslint-disable-line import/no-webpack-loader-syntax
import mainDialog from '!raw-loader!./mainDialog.upil' // eslint-disable-line import/no-webpack-loader-syntax
import notification from '!raw-loader!./notification.upil' // eslint-disable-line import/no-webpack-loader-syntax
import questionAboutHowToInterview from '!raw-loader!./questionAboutHowToInterview.upil' // eslint-disable-line import/no-webpack-loader-syntax
import questionAboutSelectionPolicy from '!raw-loader!./questionAboutSelectionPolicy.upil' // eslint-disable-line import/no-webpack-loader-syntax
import scheduling from '!raw-loader!./scheduling.upil' // eslint-disable-line import/no-webpack-loader-syntax

// Saiyodooooon
import saiyodooooonMainDialog from '!raw-loader!./saiyodooooon/mainDialog.upil' // eslint-disable-line import/no-webpack-loader-syntax

const scripts = [
  questionAboutSelectionPolicy,
  questionAboutHowToInterview,
  scheduling,
  notification,
  closing
]

switch (process.env.VUE_APP_SERVICE) {
  case 'saiyodooooon':
    scripts.push(saiyodooooonMainDialog)
    break
  default:
    scripts.push(mainDialog)
}

const combinedScripts = scripts.join('\n')

const finalScript = `
EXTERNAL jobLink
EXTERNAL baseUrl

${combinedScripts}

RUN mainDialog
`

export default finalScript
